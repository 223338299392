import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 10,
    title: "React Query",
    desc: "Ottimizzazione delle richieste di dati in App React.",
    img: "/blog-image/rQuery.jpg",
    page: "blog/react_query",
    data: "1 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Nell'ecosistema dello sviluppo web, l'efficienza delle richieste di dati è una delle sfide più importanti.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Nell'ecosistema dello sviluppo web,
                                        l'efficienza delle richieste di dati è
                                        una delle sfide più importanti. Ridurre
                                        il carico di lavoro del server e
                                        migliorare le prestazioni
                                        dell'applicazione sono obiettivi
                                        fondamentali per qualsiasi sviluppatore
                                        web. In questo articolo, esploreremo
                                        React Query, una libreria fondamentale
                                        per React, che offre un approccio
                                        efficace per gestire le richieste di
                                        dati, la cache e la sincronizzazione dei
                                        dati nell'applicazione.
                                    </p>

                                    <p>
                                        React Query è una libreria di gestione
                                        delle richieste di dati per React. È
                                        stata progettata per semplificare la
                                        gestione dello stato dei dati
                                        all'interno delle applicazioni React
                                        fornendo un modo potente per recuperare,
                                        memorizzare nella cache e sincronizzare
                                        i dati tra il front-end e il back-end.
                                        Questa libreria, sviluppata da Tanner
                                        Linsley, ha guadagnato popolarità grazie
                                        alla sua facilità d'uso e alle sue
                                        prestazioni superiori.
                                    </p>
                                    <h3>
                                        Vediamo ora le principali funzionalità
                                        di React Query che la rendono un'opzione
                                        preziosa per gli sviluppatori di React:
                                    </h3>
                                    <h5>1. Gestione automatica della cache</h5>
                                    <p>
                                        Una delle caratteristiche più potenti di
                                        React Query è la gestione automatica
                                        della cache. Quando si esegue una query
                                        per recuperare dati da un server, React
                                        Query memorizza automaticamente i
                                        risultati in una cache locale. Inoltre,
                                        la libreria offre meccanismi per
                                        invalidare o aggiornare i dati in cache
                                        quando necessario. Questo significa che
                                        non serve scrivere un codice
                                        personalizzato per gestire la cache dei
                                        dati; React Query lo fa per te in modo
                                        efficiente.
                                    </p>
                                    <h5>2. Sospensione e ricaricamento</h5>
                                    <p>
                                        React Query supporta la sospensione
                                        delle richieste di dati, il che
                                        significa che è possibile mettere in
                                        pausa una richiesta mentre un utente sta
                                        navigando altrove nell'applicazione e
                                        poi riprenderla quando torna alla vista
                                        originale. Questo è utile per
                                        ottimizzare le prestazioni e garantire
                                        che le risorse del server non vengano
                                        sprecate. Inoltre, React Query offre
                                        funzionalità di ricaricamento automatico
                                        dei dati in background, assicurandosi
                                        che i dati siano sempre aggiornati senza
                                        richiedere l'intervento dell'utente.
                                    </p>
                                    <h5>3. Sincronizzazione ottimizzata</h5>
                                    <p>
                                        La libreria offre un'ampia gamma di
                                        opzioni per la sincronizzazione dei
                                        dati. È possibile facilmente
                                        sincronizzare i dati tra più componenti
                                        React o tra diverse parti
                                        dell'applicazione. Inoltre, React Query
                                        offre un meccanismo per la
                                        sincronizzazione dei dati tra client e
                                        server in tempo reale, ad esempio
                                        utilizzando WebSocket o altre tecnologie
                                        di comunicazione in tempo reale.
                                    </p>
                                    <h5>4. Gestione degli errori</h5>
                                    <p>
                                        La gestione degli errori è un aspetto
                                        critico dello sviluppo delle
                                        applicazioni web. React Query semplifica
                                        notevolmente la gestione degli errori
                                        delle richieste di dati. Si configura
                                        facilmente il comportamento desiderato
                                        in caso di errori, come la
                                        visualizzazione di messaggi di errore
                                        utente o il ripristino automatico delle
                                        richieste fallite.
                                    </p>
                                    <h5>5. Integrazione con Redux e Mobx</h5>
                                    <p>
                                        React Query è molto facile da integrare
                                        con queste librerie di gestione dello
                                        stato esistente per sfruttare al massimo
                                        le loro funzionalità.
                                    </p>
                                    <h3>Utilizzo di React Query</h3>
                                    <p>
                                        Ora che abbiamo esaminato alcune delle
                                        principali funzionalità di React Query,
                                        vediamo come utilizzarla all'interno di
                                        un'app React.
                                    </p>
                                    <h5>
                                        1. L’installazione di React Query può
                                        avvenire tramite npm o yarn:
                                    </h5>
                                    <MyCoolCodeBlock
                                        code={`
    npm install react-query
    # o
    yarn add react-query 
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>2. Configurazione</h5>
                                    <p>
                                        Una volta installato, la configurazione
                                        di base prevede la definizione di un
                                        `QueryClientProvider` nell'entry point
                                        dell'applicazione:{" "}
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { QueryClient, QueryClientProvider } from 'react-query';

    const queryClient = new QueryClient();

    function App() {
        return (
        <QueryClientProvider client={queryClient}>
            {/* Il resto della tua applicazione */}
        </QueryClientProvider>
        );
    }
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>3. Esecuzione di Query</h5>
                                    <p>
                                        Esecuzione delle query per recuperare
                                        dati dal server. tramite l’hook
                                        `useQuery` fornito da React Query per
                                        questo scopo. Ad esempio:{" "}
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { useQuery } from 'react-query';

    function UserProfile() {
        const { data, isLoading, isError } = useQuery('userProfile', fetchUserProfile);

        if (isLoading) {
        return <div>Loading...</div>;
        }

        if (isError) {
        return <div>Error loading user profile</div>;
        }

        return (
        <div>
            <h2>{data.name}</h2>
            <p>{data.bio}</p>
        </div>
        );
    }
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        In questo esempio, stiamo eseguendo una
                                        query chiamata `'userProfile'`
                                        utilizzando la funzione
                                        `fetchUserProfile`. React Query gestisce
                                        automaticamente la cache, gli errori e
                                        la sospensione della richiesta.
                                    </p>
                                    <p>
                                        4. Oltre alle query, React Query
                                        supporta anche le mutazioni, che
                                        consentono di inviare dati al server. È
                                        possibile utilizzare l’hook
                                        `useMutation` per gestire le mutazioni
                                        in modo simile a quanto a quanto fatto
                                        con le query.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { useMutation } from 'react-query';

    function AddComment() {
        const mutation = useMutation(addComment);

        const handleSubmit = (formData) => {
        mutation.mutate(formData);
        };

        return (
        <form onSubmit={handleSubmit}>
            {/* Form input fields */}
            <button type="submit" disabled={mutation.isLoading}>
            {mutation.isLoading ? 'Adding...' : 'Add Comment'}
            </button>
            {mutation.isError && <div>Error adding comment</div>}
        </form>
        );
    }
                                        
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>5. Invalidazione della Cache</h5>
                                    <p>
                                        React Query fornisce un metodo semplice
                                        per invalidare manualmente la cache e
                                        aggiornare i dati:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { useQueryClient } from 'react-query';

    function RefreshDataButton() {
        const queryClient = useQueryClient();

        const handleClick = () => {
        queryClient.invalidateQueries('userData');
        };
    />
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        Integra React Query nel tuo progetto
                                        React per migliorare significativamente
                                        le prestazioni e la manutenibilità del
                                        codice. Per tutto il resto chiedici una
                                        consulenza.
                                    </p>
                                    <h3>Buon sviluppo a tutti.</h3>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
